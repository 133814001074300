<template>
    <PanelLayout title="Facturation">
        test
    </PanelLayout>
</template>

<script lang="ts">
import PanelLayout from "@/layouts/PanelLayout.vue";
export default {
    components: {
        PanelLayout
    }
};
</script>

<style scoped lang="scss">

</style>
